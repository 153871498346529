import { Component, Vue } from 'vue-property-decorator'
import ModalDelete from '@/components/ModalDelete/ModalDelete.vue'
import http from '@/services/http'
import Search from '@/components/Search/Search.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Card from '@/components/Card/Card.vue'
import TypeStateCCSS from '@/Models/TypeStateCCSS'
import HeaderSettings from '@/components/HeaderSettings/HeaderSettings.vue'
import Item from '@/Models/Item'
import Paginator from '@/components/Paginator/Paginator.vue'
import menuModule from '@/store/menu'
import Animation from '@/components/Animation/index.vue'
@Component({
  components: {
    Search,
    ModalDelete,
    ValidationObserver,
    ValidationProvider,
    Card,
    HeaderSettings,
    Paginator,
    Animation,
  },
})
export default class ViewTypeStateCCSS extends Vue {
  data: Array<TypeStateCCSS> = []
  objTypeStateCCSS: TypeStateCCSS = new TypeStateCCSS()
  loading = false
  delete_modal_active = false
  action = 'add'

  url = 'api/ccss/arcr_ccss_status/'
  params: Array<Item> = []

  perPage = menuModule.perPage_param

  async edit(item: any) {
    await this.confirmation(item, 'edit')
  }
  close_edit(data: any) {
    const found = this.data.indexOf(data.item)
    this.data[found].isEdit = false
    if (data.close_success) console.log('xd')
    else Object.assign(this.data[found], this.objTypeStateCCSS)
  }
  changePerPage(range_perPage: any) {
    menuModule.setPerpageParam(String(range_perPage))
    this.perPage = menuModule.perPage_param
  }

  async confirmation(obj: any, action: any) {
    let found
    switch (action) {
      /* case 'add':
        console.log('xd')
        await this.createTypeResidency(this.objTypeResidency)
        break */
      case 'OpenEdit':
        Object.assign(this.objTypeStateCCSS, obj)
        found = this.data.indexOf(obj)
        this.data[found].isEdit = true
        break
      default:
        console.log('ups')
        break
    }
  }
  async deleteObj() {
    try {
      await http.delete(
        `api/ccss/arcr_ccss_status/${this.objTypeStateCCSS.id}/`,
      )
      this.data = this.data.filter(
        (a: TypeStateCCSS) => a.id !== this.objTypeStateCCSS.id,
      )
      this.delete_modal_active = false
    } catch (error) {
      console.log(error)
      //toast.error("An error has occurred please try again.")
    }
  }
  deleteConfirm(props: any) {
    this.delete_modal_active = true
    this.objTypeStateCCSS = props
  }
  closeModal() {
    this.objTypeStateCCSS = new TypeStateCCSS()
    this.delete_modal_active = false
  }
  isData(data: any) {
    this.data = data
  }
  openCollapse() {
    this.objTypeStateCCSS = new TypeStateCCSS()
  }
  close() {
    this.objTypeStateCCSS = new TypeStateCCSS()
    ;(this.$refs.Paginator as any).paginate(1)
  }
  filterItems(items: any) {
    this.data = items.map((obj: any) => ({
      ...obj,
      isEdit: false,
      value: obj.status,
    }))
  }

  async SearchFilter(search: string) {
    this.params = [] // se limpia el arreglo de params
    this.params.push({
      text: 'search', // nombre del parametro que se agrega a la url
      value: search, // valor del parametro
    })
    const currentPage = await (this.$refs['Paginator'] as any)['current'] //Se guarda la página actual
    await (this.$refs['Paginator'] as any).paginate(currentPage) // se ejecuta el metodo de paginar sobre la misma página actual pero ahora con los debidos parametros extras
  }
}
